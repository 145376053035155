<template>
  <div class="info-page">
    <user-info :classname="className"></user-info>
    <div class="card-wrap">
      <div class="card-info">
        <p class="info-text">
          <span :class="userData.activation ? 'ok' : ''">{{
            userData.activation ? "已激活：" : "未激活："
          }}</span
          >
          <template v-if="userData.activation">有效期至{{ userData.effectiveDate }}</template>
          <template v-else>充值激活后可使用</template>
        </p>
        <div :class="userData.activation ? 'card-detail' : 'card-detail none'">
          <div class="circle right" ref="right">
          </div>
          <div class="circle left" ref="left">
          </div>
          <div class="circle-inner">
              <div class="is-open" v-if="userData.activation">
                  <span class="info">还剩</span>
                  <p class="text"><span class="flow">{{ userData.remainder }}</span>GB</p>
              </div>
            <p  v-else class="no-text">未激活</p>
          </div>
        </div>
        <div class="card-text-wrap">
          <p>
            <template v-if="userData.activation">
              本月已用：<span class="all">{{ userData.allFlow }}GB</span>
            </template>
          </p>
          <p>
            <template v-if="userData.activation">
              本月剩余：<span class="remainder">{{ userData.remainder }}GB</span>
            </template>
          </p>
          <p>
            <template v-if="userData.activation">
              本月流量有效期：截至5月31日
            </template>
            </p>
        </div>
      </div>
    </div>
    <div class="btn-con">
      <button type="button" class="btn" @click="$router.push('/recharge')">现在充值</button>
    </div>
  </div>
</template>

<script>
import UserInfo from '@/components/UserInfo'
import { mapState } from 'vuex'
export default {
  name: 'cart-info',
  components: {
    UserInfo
  },
  data () {
    return {

      className: 'info-head'
    }
  },
  created () {
    this.date = this.userData.effectiveDate
    this.rotate = this.userData.activation ? ((this.userData.allFlow - this.userData.remainder) / this.userData.allFlow).toFixed(2) * 100 : 0
    this.$nextTick(() => {
      this.goRoute()
    })
  },
  mounted () {
    document.title = this.$route.meta.title
  },
  methods: {
    goRoute () {
      const num = (this.rotate * 3.6).toFixed(2)
      if (num <= 180) {
        this.$refs.right.setAttribute('style', 'transform:rotate(' + num + 'deg)')
      } else {
        this.$refs.right.setAttribute('style', 'transform:rotate(180deg)')
        this.$refs.left.setAttribute('style', 'transform: rotate(' + (num - 180) + 'deg)')
      }
    }
  },
  computed: {
    ...mapState({
      userData: state => state.user.userData
    })
  }
}
</script>

<style lang="scss" scoped>
.info-page {
  padding-bottom: 2.5rem;
}
.info-head {
  height: 8.625rem;
}

.card-wrap {
  padding: 0 1.25rem;
  margin-top: -2.5rem;
  .card-info {
    background-color: #fff;
    border-radius: 0.625rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    padding: 1rem 0 2.1875rem;
  }
  .info-text {
    padding: 0 1.875rem;
    font-size: 0.875rem;
    color: #999;
    line-height: 1.125rem;
    span {
      color: #ff6b00;
    }
    .ok {
      color: #09cd34;
    }
  }
}
.card-detail {
  width: 13rem;
  height: 13rem;
  position: relative;
  margin: 2.5rem auto 0;
  border-radius: 50%;
  overflow: hidden;
  background-color: #09CD34;
  transform: rotate(180deg);
  &.none{
    background-color: #fff;
  }
  .circle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
    background-color:#e2e2e2;
    &.right {
      right: 0;
      clip:rect(0,auto,auto,6.5rem);
      transform: rotate(0deg);
    }
    &.left {
      left: 0;
      clip:rect(0,6.5rem,auto,0);
      transform: rotate(0deg);
    }
  }
  .circle-inner{
    width: 9.25rem;
    height: 9.25rem;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    .is-open{
      position: relative;
      .text{
        font-size: 1.125rem;
        font-weight: bold;
      }
      .flow{
        font-size: 2.25rem;
      }
      .info{
        font-size: 0.75rem;
        color: #999;
        position: absolute;
        left: 0;
        top: -.7rem;
      }
    }
    .no-text{
      font-size:1.5rem;
      font-weight: bold;
    }
  }
}
.card-text-wrap{
  line-height: 1.5rem;
  font-size: 0.875rem;
  text-align: center;
  margin-top: 1.25rem;
  color: #999;
  p{
    height: 1.5rem;
  }
  .all {
    color:#333;
    font-weight: bold;
  }
  .remainder{
    font-weight: bold;
    color:#09cd34;
  }
}
.btn-con{
  padding: 0 1.25rem;
  margin-top:4.375rem;
}

</style>
